@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500&display=swap");

body {
    font-family: "Mulish", sans-serif;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.ant-page-header {
    padding-left: 0px;
}

.ant-layout-header {
    background: #513d72;
}
.ant-menu-item {
    font-weight: 500 !important;
    letter-spacing: 0.5px;
    border-right: 4px solid transparent;
    font-size: 14px;
    color: #212b36;
}

.ant-menu-item-selected {
    transition: none !important;
    border-right: 4px solid #513d72 !important;
    color: #513d72;
}

.ant-menu-vertical {
    border-right: 0px !important;
    * {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.ant-table-thead {
    font-weight: bold !important;
}

.ant-form-item-label {
    font-weight: bold !important;
}

.ant-card {
    margin: 10px;
}

.ant-card-head-title {
    font-weight: 600;
}

.ant-breadcrumb {
    padding-bottom: 10px;
}

.ant-form-item-explain {
    font-size: 12px;
    margin: 4px 0px;
    line-height: 14px;
}
